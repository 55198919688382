<template>
	<b-card
		v-if="statistics"
		no-body
		class="card-statistics"
	>
		<b-overlay
			variant="white"
			:show="showLoading"
			spinner-variant="primary"
			blur="0"
			opacity=".75"
			rounded="sm"
		>
			<b-card-header>
				<b-card-title>Estadístiques globals</b-card-title>
			</b-card-header>
			<b-card-body class="statistics-body">
				<b-row>
					<b-col
						v-for="item in statistics"
						:key="item.icon"
						xl="2"
						sm="6"
						:class="item.customClass"
					>
						<b-media no-body>
							<b-media-aside class="mr-2">
								<b-avatar size="48" :variant="item.color">
									<feather-icon size="24" :icon="item.icon"/>
								</b-avatar>
							</b-media-aside>
							<b-media-body class="my-auto">
								<h4 class="font-weight-bolder mb-0">
									{{ item.title }}
								</h4>
								<b-card-text class="font-small-3 mb-0">
									{{ item.subtitle }}
								</b-card-text>
							</b-media-body>
						</b-media>
					</b-col>
				</b-row>
			</b-card-body>
		</b-overlay>
	</b-card>
</template>

<script>
import {
	BCard, BCardHeader, BCardTitle, BCardText, BOverlay, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BOverlay,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
	},
	data() {
		return {
			showLoading: false,
			statistics: []
		}
	},
	created() {
		this.getDashboardStatistics()
	},
	methods: {
		getDashboardStatistics() {
			this.showLoading = true

			this.$store.dispatch('statistics/countDashboard')
				.then(result => {
					this.showLoading = false
					this.statistics = result
				})
		},
	}
}
</script>
