<template>
    <section id="dashboard-ecommerce">
        <b-row class="match-height">
            <b-col
                xl="12"
                md="6"
            >
                <statistics :data="data.statisticsItems" />
            </b-col>
        </b-row>

        <b-row class="match-height">
            <!-- Company Table Card -->
            <b-col lg="12">
                <clients-table :table-data="data.companyTable" />
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import EcommerceStatistics from './EcommerceStatistics.vue'
import clientsTable from "@/views/dashboard/components/clientsTable"
import statistics from "@/views/dashboard/components/Statistics"

export default {
    components: {
        BRow,
        BCol,

        EcommerceStatistics,
        clientsTable,
        statistics,
    },
    data() {
        return {
            data: {
                congratulations: {
                    name: 'John',
                    saleToday: '48900',
                },
                statisticsItems: [
                    {
                        icon: 'UserIcon',
                        color: 'light-info',
                        title: '150',
                        subtitle: 'Clientes',
                        customClass: 'mb-2 mb-xl-0',
                    },
                    {
                        icon: 'UserCheckIcon',
                        color: 'light-primary',
                        title: '25',
                        subtitle: 'Usuarios',
                        customClass: 'mb-2 mb-xl-0',
                    },
                    {
                        icon: 'ClipboardIcon',
                        color: 'light-danger',
                        title: '47',
                        subtitle: 'Tramites',
                        customClass: 'mb-2 mb-sm-0',
                    },
                    {
                        icon: 'BarChart2Icon',
                        color: 'light-success',
                        title: '14',
                        subtitle: 'Requerimientos',
                        customClass: '',
                    },
                    {
                        icon: 'AlertOctagonIcon',
                        color: 'light-warning',
                        title: '0',
                        subtitle: 'Notificaciones',
                        customClass: '',
                    },
                    {
                        icon: 'BellIcon',
                        color: 'light-danger',
                        title: '20',
                        subtitle: 'Incidencias',
                        customClass: '',
                    },
                ],
                statisticsOrder: {
                    series: [
                        {
                            name: '2020',
                            data: [45, 85, 65, 45, 65],
                        },
                    ],
                },
                statisticsProfit: {
                    series: [
                        {
                            data: [0, 20, 5, 30, 15, 45],
                        },
                    ],
                },
                earningsChart: {
                    series: [53, 16, 31],
                },
                revenue: {
                    years: ['2020', '2019', '2018'],
                    price: '25,852',
                    budget: '56,800',
                    revenueReport: {
                        series: [
                            {
                                name: 'Earning',
                                data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
                            },
                            {
                                name: 'Expense',
                                data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
                            },
                        ],
                    },
                    budgetChart: {
                        series: [
                            {
                                data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
                            },
                            {
                                data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
                            },
                        ],
                    },
                },
                companyTable: [
                    {
                        avatarImg: require('@/assets/images/icons/toolbox.svg'),
                        title: 'Carmín',
                        subtitle: 'Torres Montanez',
                        avatarIcon: 'MonitorIcon',
                        avatarColor: 'light-success',
                        avatarTitle: 'Disponible',
                        viewTitle: '5',
                        viewsub: 'Aquesta setmana',
                        revenue: '891.2',
                        sales: '68',
                        loss: true,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/parachute.svg'),
                        title: 'Abel',
                        subtitle: 'Costabella Yagoubi',
                        avatarIcon: 'CoffeeIcon',
                        avatarColor: 'light-success',
                        avatarTitle: 'Disponible',
                        viewTitle: '10',
                        viewsub: 'Aquesta setmana',
                        revenue: '668.51',
                        sales: '97',
                        loss: false,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/brush.svg'),
                        title: 'Cristina',
                        subtitle: 'Belkassmi Lerma',
                        avatarIcon: 'WatchIcon',
                        avatarColor: 'light-success',
                        avatarTitle: 'Disponible',
                        viewTitle: '2',
                        viewsub: 'Aquesta setmana',
                        revenue: '522.29',
                        sales: '62',
                        loss: false,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/star.svg'),
                        title: 'Roman',
                        subtitle: 'Sagardui Manias',
                        avatarIcon: 'MonitorIcon',
                        avatarColor: 'light-danger',
                        avatarTitle: 'No Disponible',
                        viewTitle: '9',
                        viewsub: 'Aquesta setmana',
                        revenue: '291.01',
                        sales: '88',
                        loss: false,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/book.svg'),
                        title: 'Raimondo',
                        subtitle: 'Pesce Biserov',
                        avatarIcon: 'CoffeeIcon',
                        avatarColor: 'light-success',
                        avatarTitle: 'Disponible',
                        viewTitle: '1',
                        viewsub: 'Aquesta setmana',
                        revenue: '783.93',
                        sales: '16',
                        loss: true,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/rocket.svg'),
                        title: 'Clemente',
                        subtitle: 'Priboi Huayta',
                        avatarIcon: 'WatchIcon',
                        avatarColor: 'light-danger',
                        avatarTitle: 'No Disponible',
                        viewTitle: '7',
                        viewsub: 'Aquesta setmana',
                        revenue: '780.05',
                        sales: '78',
                        loss: false,
                    },
                    {
                        avatarImg: require('@/assets/images/icons/speaker.svg'),
                        title: 'Daniella',
                        subtitle: 'Iglesias Badita',
                        avatarIcon: 'WatchIcon',
                        avatarColor: 'light-danger',
                        avatarTitle: 'No Disponible',
                        viewTitle: '3',
                        viewsub: 'Aquesta setmana',
                        revenue: '531.49',
                        sales: '42',
                        loss: false,
                    },
                ],
                meetup: {
                    mediaData: [
                        { avatar: 'CalendarIcon', title: '24/08/2021', subtitle: '10:AM a 12:AM' },
                        { avatar: 'MapPinIcon', title: 'Vilanova i la Geltru', subtitle: 'GAG' },
                    ],
                    avatars: [
                        { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Abel Costabella Yagoubi' },
                        { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Carmín Torres Montanez' },
                        { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Roman Sagardui Manias' },
                        { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daniella Iglesias Badita' },
                        { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Clemente Priboi Huayta' },
                    ],
                },
                goalOverview: {
                    completed: '786,617',
                    inProgress: '13,561',
                    series: [83],
                },
                transactionData: [
                    {
                        mode: 'Wallet',
                        types: 'Starbucks',
                        avatar: 'PocketIcon',
                        avatarVariant: 'light-primary',
                        payment: '-$74',
                        deduction: true,
                    },
                    {
                        mode: 'Bank Transfer',
                        types: 'Add Money',
                        avatar: 'CheckIcon',
                        avatarVariant: 'light-success',
                        payment: '+$480',
                        deduction: false,
                    },
                    {
                        mode: 'Paypal',
                        types: 'Add Money',
                        avatar: 'DollarSignIcon',
                        avatarVariant: 'light-danger',
                        payment: '+$480',
                        deduction: false,
                    },
                    {
                        mode: 'Mastercard',
                        types: 'Ordered Food',
                        avatar: 'CreditCardIcon',
                        avatarVariant: 'light-warning',
                        payment: '-$23',
                        deduction: true,
                    },
                    {
                        mode: 'Transfer',
                        types: 'Refund',
                        avatar: 'TrendingUpIcon',
                        avatarVariant: 'light-info',
                        payment: '+$98',
                        deduction: false,
                    },
                ],
            }
        }
    },
    created() {

    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
