<template>
    <b-card
        v-if="tableData"
        no-body
        class="card-company-table"
    >
        <b-table
            :items="clients"
            responsive
            :fields="fields"
            class="mb-0"
        >
            <!-- nombre -->
            <template #cell(name)="data">
                <div class="d-flex align-items-center">
                    <div>
                        <div class="font-weight-bolder">
                            {{ data.item.name }}
                        </div>
                        <div class="font-small-2 text-muted">
                            {{ data.item.first_surname }}  {{ data.item.second_surname }}
                        </div>
                    </div>
                </div>
            </template>

        </b-table>
    </b-card>
</template>

<script>
import {
    BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BTable,
        BAvatar,
        BImg,
    },
    props: {
        tableData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            fields: [
                { key: 'name', label: 'Nom' },
                { key: 'phone', label: 'Telèfon' },
                { key: 'email', label: 'Email' },
            ],
            showLoading: false,
            clients: []
        }
    },
    created() {
        this.getDashboardClients()
    },
    methods: {
        getDashboardClients() {
            this.showLoading = true

            this.$store.dispatch('statistics/clientsDashboard')
            .then(result => {
                this.showLoading = false
                this.clients = result
            })
            .catch(error => {
                console.log(error)
                this.showLoading = false
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
